import styled from "styled-components"
import media from "styled-media-query"

export const SucessoWrapper = styled.section`
  color: var(--white);
  margin: auto;
  max-width: 70rem;
  padding: 5rem 5rem;
  background-color: var(--black_background_contrast);

  ${media.lessThan("large")`
    padding: .5rem;
  `}
`

export const SucessoTitle = styled.h1`
  font-weight: 800;
  letter-spacing: 0.069rem;
  line-height: 1.4;
  font-size: 2.8rem;
  color: var(--white);
  margin: 2.4rem auto 1rem;
  text-align: center;
`

export const SucessoText = styled.p`
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: 0.069rem;
  text-align: center;
`
