import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Contato/sucesso"
import * as C from "../styles/components"

const SucessoPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="Obrigado pelo contato"
      description="Obrigado por sua mensagem, retornaremos o mais breve possível"
    />
    <S.SucessoWrapper>
      <S.SucessoTitle>Obrigado pela sua mensagem!</S.SucessoTitle>
      <S.SucessoText>
        Sua mensagem foi enviada com sucesso. Retornaremos o mais breve
        possível.
      </S.SucessoText>
      <S.SucessoText>
        Enquanto isso fique a vontade para retornar ao nosso blog e continuar
        com seu aprendizado.
      </S.SucessoText>
      <br></br>
      <br></br>
      <S.SucessoText>
        <C.InterButton
          to="/"
          cover
          direction="right"
          bg="#08070e"
          duration={0.6}
        >
          Voltar a página inicial
        </C.InterButton>
      </S.SucessoText>
    </S.SucessoWrapper>
  </Layout>
)

export default SucessoPage
